@if (!showCreateForm) {
    <div class="grid grid-nogutter">
        @if (this.project) {
            <div class="p-0 mb-2 col-12 flex justify-content-end lg:justify-content-end flex-column lg:flex-row align-items-start lg:align-items-center gap-3 lg:gap-0 ">
                <p-button label="CREATE ROLE" (click)="onCreate()" [rounded]="true" icon="pi pi-plus" size="large"/>
            </div>
        }
        <div class="col-12 p-0 mb-2">
            <p class="m-0 text-black-alpha-60 lg:mt-0 xl:mt-0 mt-4 ">You’ve entered: {{projectRoles.length}} {{projectRoles.length == 1 ? 'role' : 'roles'}}</p>
        </div>
        <div class="w-full">
            <div class="col-12 p-0">
                <p-table [value]="projectRoles" styleClass="p-datatable-sm p-datatable-sm--normal" dataKey="_id" [tableStyle]="{ 'min-width': '60rem' }"
                    [expandedRowKeys]="expandedRows" (onRowExpand)="onRowExpand($event)"
                    (onRowCollapse)="onRowCollapse($event)">
                    <ng-template pTemplate="header">
                        <tr>
                            <th>
                                Role Name
                            </th>
                            <th>
                                Start Date/Time
                            </th>
                            <th>
                                Freelancer Available 
                            </th>
                            <th style="width: 8%">Action</th>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="body" class="border-0" let-role let-expanded="expanded">
                        <tr>
                            <td>{{ role.roleType }}</td>
                            <td>{{ role.startAt | date }}</td>
                            <td>{{ role.reviewersCount }}</td>
                            <td>
                                <div class="wrap-actions flex align-items-center gap-2" style="float: right;">
                                    @if(role.status === roleStatus.active){
                                        <p-button pTooltip="Edit Role" tooltipPosition="top" (click)="onEdit(role._id)" [rounded]="true" [text]="true" icon="true" plain="true">
                                            <span class="material-symbols-outlined">
                                                edit
                                            </span>
                                        </p-button>
                                        <p-button [pTooltip]="role.isStarted? 'End Role': 'Archive Role'" tooltipPosition="top" (click)="openDeleteModal(role)" [rounded]="true" [text]="true" icon="true" plain="true">
                                            <span class="red material-symbols-outlined">
                                                delete
                                            </span>
                                        </p-button>
                                        <p-button pTooltip="Send Offers" tooltipPosition="top" (click)="sendOffer(role)" [rounded]="true"
                                            [text]="true" icon="true" plain="true">
                                            <span class="material-symbols-outlined">
                                                person_add
                                            </span>
                                        </p-button>
                                    }
                                    @if(role.status === roleStatus.archive){
                                    <p-button (click)="openDeleteModal(role)" [rounded]="true" [text]="true" icon="true" plain="true">
                                        <span class="material-symbols-outlined">
                                            unarchive
                                        </span>
                                    </p-button>
                                    }
                                   
                                    
                                    <p-button type="button" pRipple [pRowToggler]="role" [text]="true"
                                        [rounded]="true" [plain]="true" [icon]="
                        expanded ? 'pi pi-chevron-down' : 'pi pi-chevron-up'
                        " class="row-toggler" />
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                    <ng-template pTemplate="rowexpansion" let-role>
                        <tr>
                            <td colspan="4" class="p-0">
                                <div class="sub-details surface-100 py-3 px-4">
                                    <div class="grid grid-nogutter">
                                        <div class="py-0 pr-4 pl-0 col-12 md:col-12 sm:col-12">
                                            <span class="text-base block font-medium mt-3">Project Type</span>
                                          </div>
                                          <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                                            @for(job of roleDetailsDisplay?.jobType; track job){
                                              <p class="m-0 text-black-alpha-60 font-medium text-sm">
                                                {{ job }}
                                              </p>
                                            }
                                          </div>
                                        <div class="py-0 pr-4 pl-0 col-12 md:col-12 sm:col-12">
                                            <span class="text-base block font-medium mt-3">Education</span>
                                          </div>
                                          <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                                            <span class="text-primary mt-2 block text-xs">Under Graduate</span>
                                           @if(roleDetailsDisplay?.underGrad?.length){
                                              @for(education of roleDetailsDisplay?.underGrad; track education){
                                                  <p class="m-0 text-black-alpha-60 font-medium text-sm">{{education.type}}-{{education.major}}</p>
                                              }
                                            } 
                                            @else{
                                              <p class="m-0 text-black-alpha-60 font-medium text-sm">Any</p>
                                            }      
                                          </div>
                                          <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                                            <span class="text-primary mt-2 block text-xs">Graduate</span>
                                            @if(roleDetailsDisplay?.graduate?.length){
                                                @for(education of roleDetailsDisplay?.graduate; track education){
                                                    <p class="m-0 text-black-alpha-60 font-medium text-sm">{{education.type}}-{{education.major}}</p>
                                                }
                                              }
                                              @else{
                                                <p class="m-0 text-black-alpha-60 font-medium text-sm">Any</p>
                                              }
                                            </div>
                                            <div class="py-0 pr-4 pl-0 col-12 md:col-12 sm:col-12">
                                              <span class="text-base block font-medium mt-3">Skills and Experience</span>
                                            </div>
                                            @if(roleDetailsDisplay?.skillsAndExperience?.length){
                                              <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                                                @for(skill of roleDetailsDisplay?.skillsAndExperience; track skill){
                                                  <p class="m-0 text-black-alpha-60 font-medium text-sm">{{skill.val}}</p>
                                                }
                                              </div>
                                            }
                                            @else{
                                              <p class="m-0 text-black-alpha-60 font-medium text-sm">Any</p>
                                            }
                                            <div class="py-0 pr-4 pl-0 col-12 md:col-12 sm:col-12">
                                                <span class="text-base block font-medium mt-3">Credentials</span>
                                              </div>
                                              @if(roleDetailsDisplay?.credential?.length){
                                                <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                                                  @for(cred of roleDetailsDisplay?.credential; track cred){
                                                    <p class="m-0 text-black-alpha-60 font-medium text-sm">{{cred.val}}</p>
                                                  }
                                                </div>
                                              }
                                              @else{
                                                <p class="m-0 text-black-alpha-60 font-medium text-sm">Any</p>
                                              }
                                        <div class="py-0 pr-4 pl-0 col-12 md:col-12 sm:col-12">
                                            <span class="text-base block font-medium mt-3">Timeline and Schedule</span>
                                        </div>
                                        <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                                            <span class="text-primary mt-2 block text-xs">Starts On</span>
                                            <p class="m-0 text-black-alpha-60 font-medium text-sm">{{role.startAt | date}}</p>
                                        </div>
                                        @if (role.workSchedule.schedule.weekDays.willWork){
                                            <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                                                <span class="text-primary mt-2 block text-xs">Monday - Friday</span>
                                                    <p class="m-0 text-black-alpha-60 font-medium text-sm">Start {{ helperService.formatTime(role.workSchedule.schedule.weekDays.time.start) }}</p>
                                                    <p class="m-0 text-black-alpha-60 font-medium text-sm">End {{ helperService.formatTime(role.workSchedule.schedule.weekDays.time.end) }}</p>
                                            </div>
                                        } 
                                        <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                                            <span class="text-primary mt-2 block text-xs">Expected Duration</span>
                                            <p class="m-0 text-black-alpha-60 font-medium text-sm">{{role.workSchedule.duration}}</p>
                                        </div>
                                        @if(role.workSchedule.schedule.saturday.willWork) {
                                            <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                                                <span class="text-primary mt-2 block text-xs">Saturday</span>
                                                <p class="m-0 text-black-alpha-60 font-medium text-sm">Start {{helperService.formatTime(role.workSchedule.schedule.saturday.time.start)}}</p>
                                                <p class="m-0 text-black-alpha-60 font-medium text-sm">End {{helperService.formatTime(role.workSchedule.schedule.saturday.time.end)}}</p>
                                            </div>
                                        }
                                        @if(role.workSchedule.workHours){
                                        <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                                            <span class="text-primary mt-2 block text-xs">Expected hours</span>
                                            <p class="m-0 text-black-alpha-60 font-medium text-sm">{{role.workSchedule.workHours}}</p>
                                        </div>
                                        }
                                        @if (role.workSchedule.schedule.sunday.willWork){
                                            <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                                                <span class="text-primary mt-2 block text-xs">Sunday</span>
                                                    <p class="m-0 text-black-alpha-60 font-medium text-sm">Start {{helperService.formatTime(role.workSchedule.schedule.sunday.time.start)}}</p>
                                                    <p class="m-0 text-black-alpha-60 font-medium text-sm">End {{helperService.formatTime(role.workSchedule.schedule.sunday.time.end)}}</p>
                                            </div>
                                        }
                                        <div class="py-0 pr-4 pl-0 col-12 md:col-12 sm:col-12">
                                            <span class="text-base block font-medium mt-3">Screening Questions</span>
                                        </div>
                                        <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                                            <span class="text-primary mt-2 block text-xs">Case Captions</span>
                                            <p class="m-0 text-black-alpha-60 font-medium text-sm">{{ captionName(project?.caption )}}</p>
                                        </div>
                                        @if(role?.hasConflict ){
                                            <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                                                <span class="text-primary mt-2 block text-xs">Conflicts Checked Against</span>
                                                @if(role?.scanForConflicts?.fullCompanyNames){
                                                <p class="m-0 text-black-alpha-60 font-medium text-sm">
                                                  Full Company Names
                                                </p>
                                                } @if(role?.scanForConflicts?.specificQuestions){
                                                <p class="m-0 text-black-alpha-60 font-medium text-sm">
                                                  Questions
                                                </p>
                                                }
                                              </div>
                                        
                                          @if(role?.scanForConflicts?.specificQuestions){
                                        @if(role?.conflictQuestions?.length){
                                            <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                                              <span class="text-primary mt-2 block text-xs">Questions</span>
                                              <ul class="m-0 list-none p-0 text-black-alpha-70 text-xs">
                                                @for(question of role.conflictQuestions; track question){
                                                <li>Q{{ $index + 1 }} - {{ question?.title }}</li>
                                                }
                                              </ul>
                                            </div>
                                            <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                                              <span class="text-primary mt-2 block text-xs">Answers</span>
                                              <ul class="m-0 list-none p-0 text-black-alpha-70 text-xs">
                                                @for(question of role.conflictQuestions; track question){
                                                <li>A{{ $index + 1 }} - {{ question?.answer? 'Yes': 'No' }}</li>
                                                }
                                              </ul>
                                            </div>
                                            }
                                        }
                                    } @else {
                                        <div class="py-0 pr-4 pl-0 col-2 md:col-2 sm:col-2">
                                            <span class="text-primary mt-2 block text-xs">Conflict Questions</span>
                                            <p class="m-0 text-black-alpha-60 font-medium text-sm">No conflict questions</p>
                                        </div>
                                      }
                                        
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </ng-template>
                </p-table>
              </div>
               <!-- delete role modal -->
    <p-dialog 
    [modal]="true" 
    draggable="false"
    [(visible)]="visibleDeleteModal" 
    [breakpoints]="{ '1199px': '75vw', '767px': '90vw' }" 
    [style]="{ width: '660px', height: selectedDeleteRole?.status === roleStatus.archive ? '50vh' : 'auto' }" >

    <ng-template pTemplate="header">
        <div class="inline-flex align-items-center justify-content-center gap-2">
            <span class="font-bold white-space-nowrap">
               Confirmation
            </span>
        </div>
    </ng-template>

    <div class="grid grid-nogutter">
        <div class="col-12 p-0 flex justify-content-between align-items-center">
            <p>  You are about to
                @if(selectedDeleteRole?.status === roleStatus.active){
                    End
                }@else {
                    Restart
                }
                <span class="font-bold">{{selectedDeleteRole?.roleType}}</span>
                Role:
            </p>
          </div>
        @if(selectedDeleteRole?.status === roleStatus.active){
            <div class="col-12 p-0 flex justify-content-between align-items-center mt-2">
                <textarea placeholder="Comment or reason for role end to be sent to reviewer(s)." rows="3" [(ngModel)]="deleteRoleComment"  pInputText id="end-reason" class="flex-auto" autocomplete="off" ></textarea>
            </div>
        }
        @if(isUnreadMessagesExist){
            <div class="col-12 p-0 flex justify-content-between align-items-center mt-2" ng-if="vm.discussions.length">
                <p class="font-twntwo">
                    <b>Note: </b> There are unread messages from one or more reviewer whose role is about to end.
                    These messages will not be readable if you continue. To read messages click “Cancel” and
                    navigate to the messaging screen.
                </p>
            </div>
        }
        @if(selectedDeleteRole?.status === roleStatus.archive){
            <p>
                Please select a new start date and time for the role.
            </p>
        <div class="col-12 p-0 flex justify-content-between align-items-center mt-2">
            <input type="text" style="display: none;" />
            <p-floatLabel>
                <p-calendar 
                    [(ngModel)]="restartDate"
                    inputId="restart_date" 
                    [minDate]="roleMinDate"
                    [maxDate]="roleMaxDate"
                    placeholder="mm/dd/yy"/>
                <label for="restart_date">Start Date</label>
            </p-floatLabel>
        </div>
       
        <div class="col-12 p-0 flex justify-content-between align-items-center mt-2">
           
                                            <p-calendar 
                                            inputId="calendar-timeonly" 
                                            [(ngModel)]="restartTime"
                                            [timeOnly]="true" 
                                            hourFormat="12"
                                            inline="true"
                                            styleClass="slider-style"
                                            />
        </div>
    }
    </div>

    <ng-template pTemplate="footer">
        <p-button label="Cancel" size="large" [rounded]="true" (onClick)="hideDeleteModal()"/>
        <p-button label="Ok" size="large" [rounded]="true" (onClick)="deleteRole()"/>
    </ng-template>
</p-dialog>
        </div>
        <div class="col-12 p-0 flex justify-content-end gap-3 mt-3">
            <p-button label="Previous" (click)="goToPrevious(1)" [outlined]="true" [rounded]="true" size="large"/>
            @if(project?.step !== '7'){
                <p-button label="Save" (click)="saveAndLogout()" [rounded]="true" size="large" styleClass="freshgreen-background"/>
            }
            @if(isCreated){
                <p-button 
                    label="Next"
                    (click)="publish('next')"
                    [rounded]="true"
                    size="large"
                    [disabled]="!isStartDateWithin7Days || disablePublishButton || !!validateRolesStartDate(role).length"
                    />
            } @else {
                <p-button label="Next" (click)="next('next')" [rounded]="true"  size="large"/>
            }

            @if(project?.step === '7'){
                <p-button label="Save" (click)="next('review')" [rounded]="true"  size="large" styleClass="freshgreen-background"/>
            }
        </div>
    </div>
    <p-dialog 
            [modal]="true" 
            draggable="false"
            [(visible)]="visiblePublishModal" 
            [breakpoints]="{ '1199px': '75vw', '767px': '90vw' }" 
            [style]="{ width: '660px' }" >

            <ng-template pTemplate="header">
                <div class="inline-flex align-items-center justify-content-center gap-2">
                    <span class="font-bold white-space-nowrap">
                        You are about to publish project.
                    </span>
                </div>
            </ng-template>

            <div class="grid grid-nogutter">
                <div class="col-12 p-0 flex justify-content-between align-items-center mt-3">
                    {{project?.codeName}}
                </div>
                <div class="col-12 p-0 flex justify-content-between align-items-center mt-3">
                    Please click "Ok" to continue. If not, please click "Cancel"
                </div>
            </div>

            <ng-template pTemplate="footer">
                <p-button label="Cancel" size="large" [rounded]="true" (onClick)="confirmPublish()"/>
                <p-button label="OK" size="large" [rounded]="true" (onClick)="confirmPublish(true)"/>
            </ng-template>
        </p-dialog>

} @else {
    <div class="w-full">
        <div class="col-11 p-0 mb-8 mx-auto">
            <div class="grid grid-nogutter">
                <div class="col-12 p-0">
                    <span class="text-base font-bold">{{roleId ? 'Edit Role' : 'Add New Role'}}</span>
                </div>
                <div class="col-12 flex justify-content-center my-3 p-2 vivid-purple-bg gap-5 align-items-center">
                    <h6 class="font-medium text-white m-0 p-2 flex align-items-center gap-2">Candidates available based on the below criteria: <h4 class="m-0">{{reviewersCount}}</h4></h6>
                    @if (showInhouseReviewer) {
                        <h6 class="font-medium text-white m-0 flex align-items-center gap-2">In-house Candidates available based on the below criteria: <h4 class="m-0">{{occupiedReviewersCount}}</h4></h6>
                    }
                </div>
                <div class="col-12">
                        <p-tabView [(activeIndex)]="activeIndex" styleClass="full-w">
                            <p-tabPanel header="INFORMATION">
                                <form [formGroup]="informationForm" (ngSubmit)="onSubmitInformationForm()">
                                <div class="grid grid-nogutter">
                                    <div class="p-0 mb-1 xl:mb-4 lg:mb-4 col-12 xl:col-6 lg:col-6">
                                        <p-floatLabel>
                                            <input formControlName="roleType" id="username" type="text" pInputText />
                                            <label for="username">Role Name<span class="required">*</span></label>
                                        </p-floatLabel>
                                        @if(infoForm['roleType'].dirty || infoForm['roleType'].touched || submitted){
                                            @if(infoForm['roleType'].errors?.['required']){
                                                <div class="p-error ">This is required.</div>
                                            }
                                        }
                                    </div>
                                    <div class="p-0 mb-0 sm:mb-4 md:mb-4 col-12 xl:col-6 lg:col-6 pl-0 xl:pl-4 lg:pl-4">
                                        <div class="switch-w-label pt-2 flex gap-2">
                                            <p-inputSwitch class="mpl-switch" inputId="stfProject" 
                                            [(ngModel)]="showInhouseReviewer" [ngModelOptions]="{standalone: true}" 
                                            (ngModelChange)="onShowInhouseReviewerSwitch()" />
                                            <label for="stfProject" class="line-height-2">Show in-house candidate count</label>
                                        </div>
                                    </div>
                                    <div class="p-0 mb-3 col-12 xl:col-6 lg:col-6">        
                                        <p-floatLabel>
                                            <p-multiSelect 
                                                formControlName="jobType" 
                                                inputId="float-label" 
                                                [options]="jobTypes"
                                                optionLabel="name" />
                                            <label for="float-label">Job Type<span class="required">*</span></label>
                                        </p-floatLabel>
                                        @if(infoForm['jobType'].dirty || infoForm['jobType'].touched || submitted){
                                            @if(infoForm['jobType'].errors?.['required']){
                                                <div class="p-error ">This is required.</div>
                                            }
                                        }
                                    </div>
                                    <div class="p-0 mb-0 sm:mb-4 md:mb-4 col-12 xl:col-6 lg:col-6 pl-0 xl:pl-4 lg:pl-4"></div>
                                    <div class="p-0 mb-2 col-12 xl:col-6 lg:col-6">
                                        <p-floatLabel>
                                            <p-multiSelect 
                                                formControlName="searchMarket" 
                                                inputId="float-label" 
                                                [options]="searchMarkets"
                                                optionLabel="name" />
                                            <label for="float-label">Search Markets<span class="required">*</span></label>
                                        </p-floatLabel>
                                        @if(infoForm['searchMarket'].dirty || infoForm['searchMarket'].touched || submitted){
                                            @if(infoForm['searchMarket'].errors?.['required']){
                                                <div class="p-error ">This is required.</div>
                                            }
                                        }
                                    </div>
                                    <div class="p-0 mb-3 col-12">
                                        <span class="text-base font-semibold">Education</span>
                                    </div>
                                    
                                    <div class="p-0 mb-3 col-12">
                                        <span class="text-base font-semibold">Undergraduate</span>
                                    </div>
                                    <div class="p-0 mb-4 col-12 xl:col-12 lg:col-12 radio-buttons">
                                        @for(category of education; track category){
                                        <div class="field-checkbox">
                                            <p-radioButton 
                                                [inputId]="category.key" 
                                                [value]="category" 
                                                formControlName="selectedunderGradEducation"/>
                                            <label [for]="category.key" class="ml-2">
                                                {{ category.name }}
                                            </label>
                                        </div>}
                                    </div>
                                <div class="p-0 col-12 xl:col-12 lg:col-12">
                                    <div class="p-0 mb-4 col-6 xl:col-6 lg:col-6">
                                    @if(infoForm['selectedunderGradEducation'].value?.key === 'S'){
                                        <p-floatLabel>
                                            <p-multiSelect 
                                                formControlName="underGraduate" 
                                                inputId="float-label" 
                                                [options]="degrees.underGraduate"
                                                optionLabel="typeMajor" />
                                            <label for="float-label">Undergraduate degrees<span class="required">*</span></label>
                                        </p-floatLabel>
                                        <span class="text-xs pl-1">Select upto five</span>
                                        @if(infoForm['underGraduate'].dirty || infoForm['underGraduate'].touched || submitted){
                                            @if(infoForm['underGraduate'].errors?.['required']){
                                                <div class="p-error ">This is required.</div>
                                            }
                                        }
                                    }
                                    </div>
                                </div>
                                <div class="p-0 mb-3 col-12">
                                    <span class="text-base font-semibold">Graduate</span>
                                </div>
                                <div class="p-0 mb-4 col-12 xl:col-12 lg:col-12 radio-buttons">
                                    @for(category of education; track category){
                                    <div class="field-checkbox">
                                        <p-radioButton 
                                            [inputId]="category.key" 
                                            [value]="category" 
                                            formControlName="selectedGraduateEducation"/>
                                        <label [for]="category.key" class="ml-2">
                                            {{ category.name }}
                                        </label>
                                    </div>}
                                </div>
                                <div class="p-0 col-12 xl:col-12 lg:col-12">
                                <div class="p-0 mb-1 xl:mb-4 lg:mb-4 col-6 xl:col-6 lg:col-6">
                                    @if(infoForm['selectedGraduateEducation'].value?.key === 'S'){
                                        <p-floatLabel>
                                            <p-multiSelect 
                                                formControlName="graduate" 
                                                inputId="float-label" 
                                                [options]="degrees.graduate"
                                                optionLabel="typeMajor" />
                                            <label for="float-label">Graduate degrees<span class="required">*</span></label>
                                        </p-floatLabel>
                                        <span class="text-xs pl-1">Select upto five</span>
                                        @if(infoForm['graduate'].dirty || infoForm['graduate'].touched || submitted){
                                            @if(infoForm['graduate'].errors?.['required']){
                                                <div class="p-error ">This is required.</div>
                                            }
                                        }
                                    }
                                </div>
                            </div>
                                    <div class="p-0 mb-2 col-12 xl:col-6 lg:col-6"></div>
                                    <div class="p-0 mb-4 col-12">
                                        <span class="text-base font-semibold">Credentials</span>
                                    </div>
                                    <div class="p-0 mb-4 col-12 xl:col-12 lg:col-12 flex flex-column gap-3">
                                        <div class="flex col-12 p-0">
                                            <div formArrayName="dynamicCredential" class="flex flex-column p-0 col-12">
                                                @for(control of dynamicCredentials.controls ;track control; let i = $index){
                                                <div class="flex flex-row mb-3 gap-3" [formGroupName]="i">
                                                    <p-floatLabel class="col-6 p-0">
                                                        <p-dropdown inputId="dropdown-{{i}}" formControlName="credential" [options]="getFilteredOptionsForCredential(i)"
                                                            (onChange)="onChangeDynamicCredentialList($event, i)" optionLabel="name" [showClear]="true">
                                                        </p-dropdown>
                                                        <label for="dropdown-{{i}}">Select a Credential</label>
                                                    </p-floatLabel>
                                                    <div class="flex flex-column">
                                                        <p-floatLabel class="p-0">
                                                            <p-multiSelect [style]="{ width: '30vw' }" inputId="float-label-{{i}}"
                                                            [options]="credentialOptions[i]" optionLabel="val" formControlName="types"/>
                                                            <label for="float-label-{{i}}" [class.light-grey]="!control.get('credential')?.value || credentialOptions[i].length === 0" >Credential Type
                                                                    <span class="required" [ngStyle]="{ display: !control.get('credential')?.value || credentialOptions[i].length === 0? 'none' : 'inline' }">*</span>
                                                            </label>
                                                        </p-floatLabel>
                                                        @if(control.get('credential')?.value && (control?.get('types')?.touched || control?.get('types')?.dirty || submitted)){
                                                            @if(control?.get('types')?.errors?.['required']){
                                                                <div class="p-error ">This is required.</div>
                                                            }
                                                        }
                                                    </div>
                                                    <p-button pTooltip="Remove" tooltipPosition="top" class="" [text]="true" size="small" type="button" (click)="removeCredential(i)"
                                                        icon="pi pi-times"></p-button>
                                                </div>
                                                }
                                            </div>
                                        </div>
                                        <div>
                                            <p-button class="p-0" (click)="addCredential()">Add More Credentials</p-button>
                                        </div>
                                    </div>
                                    
                                    <div class="p-0 mb-4 col-12">
                                        <span class="text-base font-semibold">Skills and Experience</span>
                                    </div>
                                    <div class="p-0 mb-4 col-12 xl:col-12 lg:col-12 flex flex-column gap-3">
                                        <div class="flex col-12 p-0">
                                            <div formArrayName="dynamicRoleCriteria" class="flex flex-column p-0 col-12">
                                                @for(control of dynamicCriterias.controls ;track control; let i = $index){
                                                <div class="flex flex-row mb-3 gap-3" [formGroupName]="i">
                                                    <p-floatLabel class="col-6 p-0">
                                                        <p-dropdown inputId="dropdown-{{i}}" formControlName="criteria" [options]="getFilteredOptionsForCriteria(i)"
                                                            (onChange)="onChangeDynamicCriteriaList($event, i)" optionLabel="name" [showClear]="true">
                                                        </p-dropdown>
                                                        <label for="dropdown-{{i}}">Select a Skill/Experience</label>
                                                    </p-floatLabel>
                                                    <div class="flex flex-column">
                                                        <p-floatLabel class="p-0">
                                                            <p-multiSelect [style]="{ width: '30vw' }" inputId="float-label-{{i}}"
                                                            [options]="criteriaOptions[i]" optionLabel="val" formControlName="types"/>
                                                            <label for="float-label-{{i}}" [class.light-grey]="!control.get('criteria')?.value || criteriaOptions[i].length === 0">Criteria Type
                                                            <span class="required" [ngStyle]="{ display: !control.get('criteria')?.value || criteriaOptions[i].length === 0 ? 'none' : 'inline' }">*</span>
                                                            </label>
                                                        </p-floatLabel>
                                                        @if(control.get('criteria')?.value && (control?.get('types')?.touched || control?.get('types')?.dirty || submitted)){
                                                            @if(control?.get('types')?.errors?.['required']){
                                                                <div class="p-error ">This is required.</div>
                                                            }
                                                        }
                                                    </div>
                                                    <p-button pTooltip="Remove" tooltipPosition="top" [text]="true" size="small" type="button" (click)="removeCriteria(i)"
                                                    icon="pi pi-times"></p-button>
                                                </div>
                                                }
                                            </div>
                                        </div>
                                        <div>
                                            <p-button class="p-0" (click)="addCriteria()">Add More Skills/Experience</p-button>
                                        </div>
                                    </div>
                                    
                                    <div class="p-0 mb-4 col-12">
                                        <div class="flex justify-content-start">
                                            <div class="flex flex-wrap gap-3">
                                                <span class="text-base font-semibold">Citizenship</span>
                                                <div class="flex align-items-center">
                                                    <p-radioButton formControlName="immigrationStatus" value="any"/>
                                                    <label class="ml-2">
                                                        Any 
                                                    </label>
                                                </div>
                                                <div class="flex align-items-center">
                                                    <p-radioButton formControlName="immigrationStatus" value="US Citizen" />
                                                    <label class="ml-2">
                                                        US
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="p-0 mb-4 col-12">
                                        <div class="flex justify-content-start">
                                            <div class="flex flex-wrap gap-3">
                                                <span class="text-base font-semibold">Is this role remote?</span>
                                                <div class="flex align-items-center">
                                                    <p-radioButton formControlName="isRemote" [value]="true" />
                                                    <label class="ml-2">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="flex align-items-center">
                                                    <p-radioButton formControlName="isRemote" [value]="false"/>
                                                    <label class="ml-2">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    @if(!getFormValue(informationForm, 'isRemote')){
                                        <div class="p-0 mb-4 col-12 xl:col-6 lg:col-6">
                                            <p-floatLabel>
                                                <input 
                                                    [(ngModel)]="firmLocation.location.officeName"
                                                    [ngModelOptions]="{standalone: true}"
                                                    type="text" 
                                                    pInputText placeholder="Enter the office name"
                                                    [required] = "!getFormValue(informationForm, 'isRemote')"
                                                    #officeName="ngModel"/>
                                                <label for="username">Enter the office name<span class="required">*</span></label>
                                            </p-floatLabel>
                                            @if(officeName.errors?.['required']){
                                                <div class="p-error">This is required.</div>
                                            }
                                        </div>
                                        <p class="p-0 mb-4 col-12 xl:col-6 lg:col-6 pl-0 xl:pl-4 lg:pl-4 mt-2">OR</p>
                                        <div class="flex align-items-center p-0 mb-4 col-12 xl:col-6 lg:col-6">
                                            <p-floatLabel class="col-11 p-0">
                                                <p-dropdown     
                                                    [(ngModel)]="firmLocation"
                                                    [ngModelOptions]="{standalone: true}"
                                                    [options]="firmLocationsList"
                                                    optionLabel="location.officeName"
                                                   />
                                                <label for="username">Choose a previous location</label>
                                            </p-floatLabel>
                                            <p-button class="p-0" [text]="true" size="small" type="button" (click)="clearLocation()"
                                            icon="pi pi-times"></p-button>
                                        </div>
                                        <div class="p-0 mb-4 col-12 xl:col-6 lg:col-6 pl-0 xl:pl-4 lg:pl-4">
                                            <p-floatLabel>
                                                <input 
                                                    [(ngModel)]="firmLocation.location.street"
                                                    [ngModelOptions]="{standalone: true}"
                                                    type="text" 
                                                    pInputText 
                                                    placeholder="What is the street address"
                                                    [required] = "!getFormValue(informationForm, 'isRemote')"
                                                    #street="ngModel"/>
                                                <label for="username">What is the street address<span class="required">*</span></label>
                                            </p-floatLabel>
                                            @if(street.errors?.['required']){
                                                <div class="p-error">This is required.</div>
                                            }
                                        </div>

                                        <div class="p-0 mb-4 col-12 xl:col-6 lg:col-6">
                                            <p-floatLabel>
                                                <input 
                                                    [(ngModel)]="firmLocation.location.suiteNo"
                                                    [ngModelOptions]="{standalone: true}"
                                                    type="text" 
                                                    pInputText placeholder="What is the suite number"
                                                    #suite="ngModel"/>
                                                <label for="username">What is the suite number</label>
                                            </p-floatLabel>
                                        </div>
                                        <div class="p-0 mb-4 col-12 xl:col-6 lg:col-6 pl-0 xl:pl-4 lg:pl-4">
                                            <p-floatLabel>
                                                <input 
                                                    [(ngModel)]="firmLocation.location.city"
                                                    [ngModelOptions]="{standalone: true}"
                                                    type="text" 
                                                    pInputText 
                                                    placeholder="In which city"
                                                    [required] = "!getFormValue(informationForm, 'isRemote')"
                                                    #city="ngModel"/>
                                                <label for="username">In which city<span class="required">*</span></label>
                                            </p-floatLabel>
                                            @if(city.errors?.['required']){
                                                <div class="p-error">This is required.</div>
                                            }
                                        </div>

                                        <div class="p-0 mb-4 col-12 xl:col-6 lg:col-6">
                                            <p-floatLabel>
                                                <p-dropdown 
                                                    [(ngModel)]="firmLocation.location.state"
                                                    [ngModelOptions]="{standalone: true}"
                                                    [options]="states"
                                                    placeholder="State"
                                                    [required] = "!getFormValue(informationForm, 'isRemote')"
                                                    #state="ngModel"
                                                    ></p-dropdown>
                                                <label for="username">State<span class="required">*</span></label>
                                            </p-floatLabel>
                                            @if(state.errors?.['required']){
                                                <div class="p-error">This is required.</div>
                                            }
                                        </div>
                                        <div class="p-0 mb-4 col-12 xl:col-6 lg:col-6 pl-0 xl:pl-4 lg:pl-4">
                                            <p-floatLabel>
                                                <input 
                                                    [(ngModel)]="firmLocation.location.zipCode"
                                                    [ngModelOptions]="{standalone: true}"
                                                    type="text" 
                                                    pInputText 
                                                    pattern="^\b\d{5}(-\d{4})?\b$"
                                                    placeholder="What is the zipcode"
                                                    #zipCode="ngModel"
                                                    [required] = "!getFormValue(informationForm, 'isRemote')"/>
                                                <label for="username">What is the zipcode<span class="required">*</span></label>
                                            </p-floatLabel>
                                            @if(zipCode.errors?.['pattern']){
                                                <div class="p-error">Invalid zip code.</div>
                                            }
                                            @if(zipCode.errors?.['required']){
                                                <div class="p-error">This is required.</div>
                                            }
                                        </div>
                                    }
                                    <div class="p-0 mb-2 col-12">
                                        <span class="text-base font-semibold">Point of Contact information</span>
                                    </div>
                                    <div class="p-0 mb-4 col-12 xl:col-6 lg:col-6" formGroupName="reviewCenterLocation">
                                        <p-floatLabel>
                                            <input formControlName="pointOfContact" type="text" pInputText/>
                                            <label for="username">Name<span class="required">*</span></label>
                                        </p-floatLabel>
                                        @if(informationForm.get('reviewCenterLocation.pointOfContact')?.dirty || informationForm.get('reviewCenterLocation.pointOfContact')?.touched || submitted){
                                            @if(informationForm.get('reviewCenterLocation.pointOfContact')?.errors?.['required']){
                                                <div class="p-error ">This is required.</div>
                                            }
                                            @if(informationForm.get('reviewCenterLocation.pointOfContact')?.errors?.['pattern']){
                                                <div class="p-error ">Invalid Point of contact.</div>
                                            }
                                        }
                                    </div>
                                    <div class="p-0 mb-4 col-12 xl:col-6 lg:col-6 pl-0 xl:pl-4 lg:pl-4" formGroupName="reviewCenterLocation">
                                        <p-floatLabel>
                                            <input formControlName="email" type="email" pInputText />
                                            <label for="username">Email
                                                @if(!getNestedInformationFormValue('reviewCenterLocation', 'phoneNumber')){
                                                    <span class="required">*</span>
                                                }
                                            </label>
                                        </p-floatLabel>
                                        @if((informationForm.get('reviewCenterLocation.email')?.dirty || informationForm.get('reviewCenterLocation.email')?.touched || submitted)){
                                            @if(!getNestedInformationFormValue('reviewCenterLocation', 'email') && !getNestedInformationFormValue('reviewCenterLocation', 'phoneNumber')){
                                                <div class="p-error ">This is required.</div>
                                            }
                                            @if(informationForm.get('reviewCenterLocation.email')?.errors?.['pattern']){
                                                <div class="p-error ">Invalid Email.</div>
                                            }
                                        }
                                    </div>
                                    <div class="p-0 mb-2 col-12">
                                        <span class="text-base font-semibold">Candidate assistance contact phone number</span>
                                    </div>
                                    <div class="p-0 mb-4 col-12 xl:col-6 lg:col-6" formGroupName="reviewCenterLocation">
                                        <p-floatLabel>
                                            <input (input)="formatPhone($event)" maxlength="12" placeholder="000-000-0000" pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}" formControlName="phoneNumber" type="text" pInputText />
                                            <label for="username">Contact
                                                @if(!getNestedInformationFormValue('reviewCenterLocation', 'email')){
                                                    <span class="required">*</span>
                                                }
                                            </label>
                                        </p-floatLabel>
                                        @if((informationForm.get('reviewCenterLocation.phoneNumber')?.dirty || informationForm.get('reviewCenterLocation.phoneNumber')?.touched) || submitted  ){
                                            @if(!getNestedInformationFormValue('reviewCenterLocation', 'email') && !getNestedInformationFormValue('reviewCenterLocation', 'phoneNumber')){
                                                <div class="p-error ">This is required.</div>
                                            }
                                            @if(informationForm.get('reviewCenterLocation.phoneNumber')?.errors?.['pattern']){
                                                <div class="p-error ">Invalid phone number.</div>
                                            }
                                        }
                                    </div>
                                    <div class="p-0 mb-2 col-12">
                                        <span class="text-base font-semibold">Additional instructions to be conveyed to candidates.</span>
                                    </div>
                                    <div class="p-0 mb-4 col-12" formGroupName="reviewCenterLocation">
                                        <textarea 
                                        rows="5"
                                        cols="30" 
                                        pInputTextarea 
                                        formControlName="instructions"
                                        placeholder="Write Message...">
                                        </textarea>
                                    </div>
                                </div>
                                <div class="col-12 p-0 flex justify-content-end gap-3">
                                    @if(roleId)
                                    {
                                        <p-button label="Cancel" (click)="onCancel()" [outlined]="true" [rounded]="true" size="large"/>
                                        <p-button (click)="onSubmit()" label="{{btnlabel}}" [rounded]="true" size="large" styleClass="freshgreen-background"/>
                                    } 
                                    @else{
                                        <p-button label="Cancel" (click)="onCancel()" [outlined]="true" [rounded]="true" size="large"/>
                                        <p-button type="submit" label="Next" [rounded]="true" size="large" styleClass="freshgreen-background"/>
                                    }
                                </div>
                            </form>
                            </p-tabPanel>  
                            <p-tabPanel [disabled]="!(informationForm.valid && isReviewerValidated())" header="SCHEDULING">
                                <form [formGroup]="schedulingForm" (ngSubmit)="onSubmitSchedulingForm()">
                                    <div class="grid grid-nogutter">
                                        <div class="p-0 col-12 xl:col-6 lg:col-6 mb-3">
                                            <p-floatLabel>
                                                @if(roleId && isProjectStarted && isRoleStarted){
                                                    <p-calendar
                                                        formControlName="startDate"
                                                        placeholder="mm/dd/yy"/>
                                                }
                                                @else {
                                                    <p-calendar
                                                    formControlName="startDate"
                                                    [minDate]="roleMinDate"
                                                    [keepInvalid]="true"
                                                    placeholder="mm/dd/yy"/>
                                                }
                                                <label>Start Date<span class="required">*</span></label>
                                            </p-floatLabel>
                                            @if(scheduleForm['startDate'].dirty || scheduleForm['startDate'].touched || submitted){
                                                @if(scheduleForm['startDate'].errors?.['required']){
                                                    <div class="p-error ">This is required.</div>
                                                }
                                            }
                                        </div>
                                        <div class="p-0 mb-1 col-12 xl:col-6 lg:col-6 pl-0 xl:pl-4 lg:pl-4 mb-3">
                                            <p-floatLabel>
                                                <p-calendar 
                                                inputId="calendar-timeonly" 
                                                formControlName="startTime"
                                                [timeOnly]="true" 
                                                hourFormat="12"
                                                styleClass="slider-style"
                                                />
                                                <label for="time">Start Time<span class="required">*</span></label>
                                            </p-floatLabel>
                                        </div>
                                        <div class="p-0 mb-2 col-12 xl:col-6 lg:col-6">
                                            <p-floatLabel>
                                                <p-dropdown 
                                                    formControlName="timezone"
                                                    [options]="timezones"
                                                    optionLabel="name" />
                                                <label for="float-label">Timezone<span class="required">*</span></label>
                                            </p-floatLabel>
                                            @if(scheduleForm['timezone'].dirty || scheduleForm['timezone'].touched || submitted){
                                                @if(scheduleForm['timezone'].errors?.['required']){
                                                    <div class="p-error ">This is required.</div>
                                                }
                                            }
                                        </div>
                                        <div class="p-0 mb-4 col-12 xl:col-6 lg:col-6 pl-0 xl:pl-4 lg:pl-4">
                                            <p-floatLabel>
                                                @if(roleId && isProjectStarted && isRoleStarted){
                                                    <p-calendar
                                                    formControlName="displayStaffBy"
                                                    placeholder="mm/dd/yy"/>
                                                }
                                                @else{
                                                    <p-calendar
                                                        formControlName="displayStaffBy"
                                                        placeholder="mm/dd/yy"
                                                        [keepInvalid]="true" 
                                                        [minDate]="staffByMinDate"
                                                        [maxDate]="staffByMaxDate"/>
                                                }
                                                <label>Staff By Date<span class="required">*</span></label>
                                            </p-floatLabel>
                                            @if(!isGreaterThanTheCurrentDate){
                                                <div class="p-error ">
                                                    Staff by date must be after the current date.
                                                </div>
                                            }
                                            @if(!isStaffByDateLessThanRoleStartDate){
                                                <div class="p-error ">
                                                    Staff by date must be before role start date.
                                                </div>
                                            }
                                            @if(scheduleForm['displayStaffBy'].dirty || scheduleForm['displayStaffBy'].touched || submitted){
                                                @if(scheduleForm['displayStaffBy'].errors?.['required']){
                                                    <div class="p-error ">This is required.</div>
                                                }
                                            }

                                        </div>
                                        <div class="p-0 mb-3 col-12">
                                            <span class="text-base font-semibold">How long is the Role Duration<span class="required">*</span></span>
                                        </div>
                                        <div class="p-0 mb-1 xl:mb-4 lg:mb-4 col-12 xl:col-6 lg:col-6">
                                            <p-floatLabel >
                                                <p-dropdown
                                                    formControlName="duration"
                                                    [options]="roleDurations"
                                                    optionLabel="label" />
                                            </p-floatLabel>
                                            @if(((scheduleForm['duration'].dirty || scheduleForm['duration'].touched) || submitted) && !getFormValue(schedulingForm, 'duration')){
                                                <div class="p-error ">This is required.</div>
                                            }
                                        </div>
                                        <div class="p-0 col-12 xl:col-6 lg:col-6"></div>
                                        <div class="p-0 mb-3 col-12">
                                            <span class="text-base font-semibold">What are the weekly Expected Hours</span>
                                        </div>
                                        <div class="p-0 mb-1 xl:mb-4 lg:mb-4 col-12 xl:col-6 lg:col-6">
                                            <p-floatLabel >
                                                <p-dropdown
                                                    formControlName="workHours"
                                                    [options]="weeklyExpectedHours"
                                                    optionLabel="label"
                                                    [showClear]="true"/>
                                            </p-floatLabel>
                                            @if(((scheduleForm['workHours'].dirty || scheduleForm['workHours'].touched) || submitted)){
                                                @if(scheduleForm['workHours'].errors?.['required']){
                                                    <div class="p-error ">This is required.</div>
                                                }
                                            }
                                        </div>
                                        <div class="p-0 mb-2 col-12 xl:col-6 lg:col-6"></div>
                                        <div class="p-0 mb-4 col-12">
                                            <span class="text-base font-semibold">Select Expected Days and Timing</span>
                                        </div>
            
                                        <div class="p-0 mb-4 col-12 xl:col-6 lg:col-6 flex align-items-center gap-4">
                                            <p-checkbox 
                                            [binary]="true" 
                                            formControlName="weekDaysWillWork"/>
                                            <label>Monday - Friday</label>
                                        </div>
            
                                        @if(getFormValue(schedulingForm, 'weekDaysWillWork')){
                                            <div class="flex p-0 mb-4 col-12 xl:col-2 lg:col-2 pl-0 xl:pl-4 lg:pl-4">
                                               <p-floatLabel>
                                                   <p-calendar 
                                                       formControlName="weekdayStartTime"
                                                       inputId="calendar-timeonly" 
                                                       [timeOnly]="true" 
                                                       hourFormat="12"
                                                       styleClass="slider-style"
                                                       />
                                                   <label for="time">Start</label>
                                                </p-floatLabel>
                                            </div>
                
                                            <div class="flex p-0 mb-4 col-12 xl:col-3 lg:col-3 pl-0 xl:pl-4 lg:pl-4">
                                               <p-floatLabel>
                                                   <p-calendar 
                                                       formControlName="weekdayEndTime"
                                                       inputId="calendar-timeonly" 
                                                       [timeOnly]="true" 
                                                       hourFormat="12"
                                                       styleClass="slider-style"
                                                   />
                                                   <label for="time">End</label>
                                                </p-floatLabel>
                                            </div>
                                        } @else {
                                            <div class="flex p-0 mb-4 col-12 xl:col-3 lg:col-3 pl-0 xl:pl-4 lg:pl-4">
                                            </div>
                                            <div class="flex p-0 mb-4 col-12 xl:col-3 lg:col-3 pl-0 xl:pl-4 lg:pl-4">
                                            </div>
                                        }
            
                                        <div class="p-0 mb-4 col-12 xl:col-6 lg:col-6 flex align-items-center gap-4">
                                            <p-checkbox 
                                                [binary]="true" 
                                                formControlName="saturdayWillWork"
                                                (onChange)="onSaturdayCheck()" 
                                                />
                                                <label>Saturday</label>
                                                </div>
        
                                        @if (getFormValue(schedulingForm, 'saturdayWillWork')) {
                                            <div class="flex p-0 mb-4 col-12 xl:col-2 lg:col-2 pl-0 xl:pl-4 lg:pl-4">
                                               <p-floatLabel>
                                                   <p-calendar 
                                                       formControlName="saturdayStartTime"
                                                       inputId="calendar-timeonly" 
                                                       [timeOnly]="true" 
                                                       hourFormat="12"
                                                       styleClass="slider-style"
                                                       />
                                                   <label for="time">Start</label>
                                               </p-floatLabel>
                                            </div>
                
                                            <div class="flex p-0 mb-4 col-12 xl:col-3 lg:col-3 pl-0 xl:pl-4 lg:pl-4">
                                               <p-floatLabel>
                                                   <p-calendar 
                                                   formControlName="saturdayEndTime"
                                                   inputId="calendar-timeonly" 
                                                   [timeOnly]="true" 
                                                   hourFormat="12"
                                                   styleClass="slider-style"
                                                   />
                                                   <label for="time">End</label>
                                                </p-floatLabel>
                                            </div>
                                        } @else {
                                            <div class="flex p-0 mb-4 col-12 xl:col-3 lg:col-3 pl-0 xl:pl-4 lg:pl-4">
                                            </div>
                                            <div class="flex p-0 mb-4 col-12 xl:col-3 lg:col-3 pl-0 xl:pl-4 lg:pl-4">
                                            </div>
                                        }
            
                                        <div class="p-0 mb-4 col-12 xl:col-6 lg:col-6 flex align-items-center gap-4">
                                            <p-checkbox 
                                            [binary]="true" 
                                            formControlName="sundayWillWork"
                                            (onChange)="onSundayCheck()" 
                                            />
                                            <label>Sunday</label>
                                        </div>
            
                                        @if (getFormValue(schedulingForm, 'sundayWillWork')) {
                                            <div class="flex p-0 mb-4 col-12 xl:col-2 lg:col-2 pl-0 xl:pl-4 lg:pl-4">
                                               <p-floatLabel>
                                                   <p-calendar 
                                                       formControlName="sundayStartTime"
                                                       inputId="calendar-timeonly" 
                                                       [timeOnly]="true" 
                                                       hourFormat="12"
                                                       styleClass="slider-style"
                                                   />
                                                   <label for="time">Start</label>
                                                </p-floatLabel>
                                            </div>
                
                                            <div class="flex p-0 mb-4 col-12 xl:col-3 lg:col-3 pl-0 xl:pl-4 lg:pl-4">
                                               <p-floatLabel>
                                                   <p-calendar 
                                                       formControlName="sundayEndTime"
                                                       inputId="calendar-timeonly" 
                                                       [timeOnly]="true" 
                                                       hourFormat="12"
                                                       styleClass="slider-style"
                                                   />
                                                   <label for="time">End</label>
                                                </p-floatLabel>
                                            </div>
                                        } @else {
                                            <div class="flex p-0 mb-4 col-12 xl:col-3 lg:col-3 pl-0 xl:pl-4 lg:pl-4">
                                            </div>
                                            <div class="flex p-0 mb-4 col-12 xl:col-3 lg:col-3 pl-0 xl:pl-4 lg:pl-4">
                                            </div>
                                        }
                                    </div>
                                     <div class="col-12 p-0 flex justify-content-end gap-3">
                                        @if(roleId)
                                        {
                                            <p-button label="Cancel" (click)="onCancel()" [outlined]="true" [rounded]="true" size="large"/>
                                            <p-button (click)="onSubmit()" label="{{btnlabel}}" [rounded]="true" size="large" styleClass="freshgreen-background"/>
                                        } 
                                        @else {
                                            <p-button label="Cancel" (click)="onCancel()" [outlined]="true" [rounded]="true" size="large"/>
                                            <p-button type="submit" label="Next" [rounded]="true" size="large" styleClass="freshgreen-background"/>
                                        }
                                    </div>
                                </form>
                            </p-tabPanel>  
                            <p-tabPanel [disabled]="!(informationForm.valid && schedulingForm.valid && isReviewerValidated())" header="SCREENING QUESTIONS ">
                                <form [formGroup]="screeningForm" (ngSubmit)="onSubmit()">
                                <div class="grid grid-nogutter">
                                    <div class="p-0 mb-5 col-12">
                                        <div class="flex justify-content-start">
                                            <div class="flex flex-wrap gap-3">
                                                <span class="text-base font-semibold">Does this role have Screening questions?</span>
                                                <div class="flex align-items-center w-full xl:w-auto lg:w-auto">
                                                    <p-radioButton [value]="true" formControlName="hasConflict" />
                                                    <label class="ml-2">
                                                        Yes
                                                    </label>
                                                </div>
                                                <div class="flex align-items-center">
                                                    <p-radioButton [value]="false" formControlName="hasConflict" />
                                                    <label class="ml-2">
                                                        No
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    @if (screeningForm.controls['hasConflict'].getRawValue()) {
                                        <div class="col-12">
                                            <span class="text-sm mb-3 block">
                                                Enter any required screening questions below</span>
                                        </div>
                                        <div class="col-12 lg:col-4 xl:col-4">
                                            <p-floatLabel>
                                                <input formControlName="question" type="text" pInputText  placeholder="Enter your Yes-No questions here"/>
                                                <label for="username">Specific Questions</label>
                                            </p-floatLabel>
                                        </div>
                                        <div class="col-12 lg:col-8 xl:col-8 pl-0 xl:pl-3 lg:pl-3 pt-3 xl:pt-0 lg:pt-0 flex justify-content-end xl:justify-content-start lg: justify-content-start">
                                            <p-button label="ADD" (click)="addQuestion()" icon="pi pi-plus" [rounded]="true" size="large"/>
                                        </div>
                                        <div class="col-12 mb-4">
                                            <ul cdkDropList class="m-0 mt-3 dragable-list list-none" (cdkDropListDropped)="drop($event)">
                                                @for(product of conflictQuestions; track product; let i = $index){
                                                    <li class="col-12 xl:col-10 pl-2 xl:pl-6 p-2 pb-0 align-items-center border-300 border-bottom-1 xl:border-none list-none surface-100" cdkDrag>
                                                        <div class="grid grid-nogutter">
                                                            <div class="col-5 border-none xl:border-bottom-1 border-300 white-space-normal text-sm font-normal">{{ product.title }}</div>
                                                            <div class="col-3 pl-1 xl:pl-3 lg:pl-3  p-0 py-2">
                                                                <div class="flex justify-content-start">
                                                                    <div class="flex flex-wrap gap-3">
                                                                        <div class="flex align-items-center">
                                                                            <p-radioButton [(ngModel)]="product.answer"
                                                                            [ngModelOptions]="{standalone: true}" [value]="true"  />
                                                                            <label  class="ml-2">
                                                                                Yes
                                                                            </label>
                                                                        </div>
                                                                        <div class="flex align-items-center">
                                                                            <p-radioButton [(ngModel)]="product.answer"
                                                                            [ngModelOptions]="{standalone: true}" [value]="false" />
                                                                            <label class="ml-2">
                                                                                No
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-4 pl-0 flex justify-content-end gap-4 align-items-center icons">
                                                                <span (click)="removeQuestion(i)" class="red material-symbols-outlined cursor-pointer">
                                                                    delete
                                                                </span>
                                                                <span class="material-symbols-outlined text-gray-600">
                                                                    drag_indicator
                                                                </span>
                                                            </div>
                                                        </div>
                                                    </li>
                                                }
                                            </ul>
                                        </div>
                                    }
                                    
                                </div>
                                <div class="col-12 p-0 flex justify-content-end gap-3">
                                   @if(roleId)
                                    {
                                        <p-button label="Cancel" (click)="onCancel()" [outlined]="true" [rounded]="true" size="large"/>
                                        <p-button type="submit" label="{{btnlabel}}" [rounded]="true" size="large" styleClass="freshgreen-background"/>
                                    } 
                                    @else
                                    {
                                        <p-button label="Cancel" (click)="onCancel()" [outlined]="true" [rounded]="true" size="large"/>
                                        <p-button type="submit" label="{{btnlabel}}" [rounded]="true" size="large" styleClass="freshgreen-background"/>
                                    }
                                </div>
                            </form>
                            </p-tabPanel>
                        </p-tabView>
                </div>
            </div>
        </div>
    </div>
    <!--   -->

    <p-dialog 
            [modal]="true" 
            draggable="false"
            [(visible)]="visibleSaveModal"
            [breakpoints]="{ '1199px': '75vw', '767px': '90vw' }" 
            [style]="{ width: '660px' }" >

            <ng-template pTemplate="header">
                <span class="text-bold">
                    Apply Screening Questions?
                </span>
            </ng-template>
            <div class="inline-flex align-items-center justify-content-center gap-2">
                <span class="white-space-nowrap">
                    Would you like this role’s screening questions to be applied to any other <br> roles created on {{this.project!.codeName}}?
                </span>
            </div>

            <ng-template pTemplate="footer">
                <p-button styleClass="freshgreen-background" label="Yes" size="large" [rounded]="true" (onClick)="saveRole(true)"/>
                <p-button label="No" severity="danger" size="large" [rounded]="true" (click)="saveRole(false)"/>
            </ng-template>
    </p-dialog>

   
}
