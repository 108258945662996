import { Component, CSP_NONCE, OnInit } from '@angular/core';
import { DialogService, DynamicDialogConfig, DynamicDialogModule, DynamicDialogRef } from 'primeng/dynamicdialog';
import { UserService } from '../../../../../../../shared/services/user.service';
import { ConfirmationDialogService } from '../../../../../../../shared/services/confirmation-dialog.service';
import { ToastService } from '../../../../../../../shared/services/toast.service';
import { Reviewer } from '../../../../../../../shared/interfaces/reviewer.interface';
import { FloatLabelModule } from 'primeng/floatlabel';
import { DialogModule } from 'primeng/dialog';
import { ButtonModule } from 'primeng/button';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { CommonModule } from '@angular/common';
import { PeopleService } from '../../../../../../../shared/services/people.service';
import { UserClientVm } from '../../../../../../../store/user/user.interface';

@Component({
  selector: 'app-request-information',
  standalone: true,
  imports: [
    FloatLabelModule,
    DialogModule,
    ButtonModule,
    FormsModule,
    InputTextareaModule,
    CommonModule,
    ReactiveFormsModule,
    DynamicDialogModule
  ],
  templateUrl: './request-information.component.html',
  styleUrl: './request-information.component.scss'
})
export class RequestInformationComponent implements OnInit{

  reviewer!: Reviewer;
  infoForm!: FormGroup;
  firmName!: string;
  text = [];
  constructor(private ref: DynamicDialogRef, public config: DynamicDialogConfig, private userService: UserService,
    private toastService: ToastService, public dialogService: DialogService,private fb: FormBuilder, private peopleService: PeopleService
  ) { }

  ngOnInit(): void {
    this.reviewer = this.config.data.reviewer;
    this.infoForm = this.fb.group({
      information: ['', [Validators.required]],
    });
  }

  onSubmit(): void{
    const info = this.infoForm.controls['information']?.value;
    this.peopleService.requestInformation(this.reviewer._id, info).subscribe((response: any) => {
      this.toastService.showSuccess('Information requested successfully.');
      this.infoForm.reset();
      this.closeDialog();
    });
  }

  closeDialog() {
    this.ref.close();
  }
}
