@if (projectDetails) {
    <app-heading [title]="helperService.capitalizeFirstLetter(projectDetails?.status != 'CREATED' ? projectDetails?.status : 'UNPUBLISHED') + ' Projects'" [button]="projectDetails?.status !== 'ARCHIVED' && headingButton" [showToggle]="false"></app-heading>
    <div class="wrap-project-details">
        <div class="wrap-doc-details">
            <div class="name">
                <span>{{projectDetails?.codeName}}</span>
                <p class="m-0">{{captionName(projectDetails?.caption)}}</p>
            </div>
            <div class="date-position">
                <p class="m-0">Published: {{projectDetails?.publishedAt | date:'MM/dd/yyyy' }}</p>
                <p class="m-0">Positions: {{projectPositionsListed}}/{{projectPositionsFilled}}</p>
            </div>
        </div>
        <p-tabView styleClass="full-w">
            @if(projectDetails.status != 'CREATED') {
                <p-tabPanel header="STAFF">
                    @if(!isLoading){
                        <app-staff-tab [projectPositionsFilled]="projectPositionsFilled" [projectDetails]="projectDetails" [projectId]="projectId" [projectStaffList]="projectStaffList" (updateAndReInitializeStaff)="updateAndReInitializeStaff()"></app-staff-tab>
                    }
                </p-tabPanel>
                <p-tabPanel header="RESPONSE">
                    <app-response-tab [projectId]="projectId"></app-response-tab>
                </p-tabPanel>
            }
            <p-tabPanel header="DETAILS">
                <div class="grid">
                    @if (projectDetails) {
                        <div class="col-12 md:col-12 sm:col-12">
                            <div class="last-project p-0 border-round-2xl bg-white shadow-3 mb-4">
                                <div class="card-head flex align-items-center justify-content-between py-2 px-3 border-round-2xl border-noround-bottom bg-indigo-50">
                                    <span class="text-lg font-medium text-primary-900">Required Documents</span>
                                    
                                    <p-button (click)="openDocumentEditModal($event, projectDetails.documents )"
                                    [link]="true" [text]="true" styleClass="text-primary-900 p-0 gap-2">
                                        <span class="material-symbols-outlined text-primary-900"> edit </span>
                                        EDIT
                                    </p-button>
                                </div>
                                <div class="card-body px-3 py-2">
                                    <div class="wrap-details">
                                        <ul class="filename m-0 h-6rem overflow-auto flex-row gap-2">
                                            @if(projectDetails?.documents.length){
                                                @for(document of projectDetails?.documents; track document ){
                                                <li class="flex align-items-center justify-content-between">
                                                    <span class="text-sm font-medium w-40">{{document.docTitle}}</span>
                                                    @if (document.userTitle) {
                                                        <div class="w-45">
                                                            <span class="text-sm font-medium">Instructions: </span>
                                                            <span>{{ document.userTitle }} </span>
                                                        </div>
                                                    }
                                                    <div class="actions flex align-items-center gap-2 w-3">
                                                        <p-button pTooltip="Download" 
                                                        tooltipPosition="top" [rounded]="true" (click)="downloadDocument(document)" [text]="true" icon="true">
                                                            <span class="material-symbols-outlined">
                                                                download
                                                            </span>
                                                        </p-button>
                                                    </div>
                                                </li>
                                                }
                                            }
                                            @if(projectDetails?.firm?.isDocusignEnabled){
                                                @if(projectDetails?.docuSignTemplates?.length){
                                                    @for(template of projectDetails?.docuSignTemplates; track template ){
                                                        @for(document of template?.documents; track document ){
                                                            <li class="flex align-items-center justify-content-between">
                                                                <span class="text-sm font-medium">{{document.name}}</span>
                                                                <div class="actions flex align-items-center gap-2">
                                                                    <p-button [rounded]="true" [text]="true" icon="true"
                                                                        (click)="viewDocument(template.templateId,document.documentId,document.name)">
                                                                        <span class="material-symbols-outlined text-primary-900">
                                                                            download
                                                                        </span>
                                                                    </p-button>
                                                                </div>
                                                            </li>
                                                        }
                                                    }
                                                }
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
        
                        <div class="col-12 sm:col-12">
                            <app-details-tab [projectId]="projectId"></app-details-tab>
                        </div>      
                    }
                    
                </div>
            </p-tabPanel>
        </p-tabView>
    </div>
}
